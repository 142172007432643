/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsVariwide from 'highcharts/modules/variwide';
import { RoomChartViewModel } from 'models/dtos/RoomChartView';
import { useSleepDuration } from '../../../api/Hooks';

HighchartsVariwide(Highcharts);
export const SleepPhasesChart: React.FC<RoomChartViewModel> = ({
    chartTitle,
    sensorIdMainRoom,
    sensorIdBathRoom,
    startDate,
}) => {
    const isDetailed = true;
    const { data } = useSleepDuration(
        startDate,
        sensorIdMainRoom,
        sensorIdBathRoom,
        isDetailed
    );

    const series1 = data
        ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .filter((value) => value.locationId === 1)
        .map((entry) => {
            const result = {
                x: new Date(entry.timestamp.toString()).getTime(),
                y: entry.value,
                z: entry.durationMs,
                color:
                    entry.value === 0
                        ? '#e74c3c'
                        : entry.value === 1
                        ? '#3498db'
                        : entry.value === 2
                        ? '#f1c40f'
                        : entry.value === 3
                        ? '#2ecc71'
                        : entry.value === 4
                        ? '#9b59b6'
                        : '#34495e',
            };
            return result;
        });

    const series2 = data
        ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .filter((value) => value.locationId === 2)
        .map((entry) => {
            const result = {
                x: new Date(entry.timestamp.toString()).getTime(),
                y: entry.value,
                z: entry.durationMs,
                color:
                    entry.value === 0
                        ? '#e74c3c'
                        : entry.value === 1
                        ? '#3498db'
                        : entry.value === 2
                        ? '#f1c40f'
                        : entry.value === 3
                        ? '#2ecc71'
                        : entry.value === 4
                        ? '#9b59b6'
                        : '#34495e',
            };
            return result;
        });

    const series = [];
    if (series1 !== undefined && series1?.length > 0) {
        series.push({
            name: data?.find((entry) => entry.locationId === 1)?.location,
            data: series1,
            dataGrouping: {},
        });
    }
    if (series2 !== undefined && series2?.length > 0) {
        series.push({
            name: data?.find((entry) => entry.locationId === 2)?.location,
            data: series2,
            dataGrouping: {},
        });
    }

    const options = {
        chart: {
            type: 'variwide',
        },
        animationLimit: 'Infinity',
        plotOptions: {
            series: {
                turboThreshold: 0,
            },
        },
        title: {
            text: chartTitle,
        },

        subtitle: {
            text: 'StaySafe',
        },

        xAxis: {
            type: 'datetime',
        },

        yAxis: {
            min: 0,
            max: 5,
            categories: [
                'Ukjent status',
                'ikke tilstede',
                'Våken',
                'REM',
                'Lett',
                'Dyp',
            ],
        },

        caption: {
            text: 'Søvnfaser forklaring',
        },

        legend: {
            enabled: false,
        },

        series,
        time: {
            useUTC: false,
        },
        tooltip: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter(this: any) {
                const timezoneOffset =
                    new Date().getTimezoneOffset() * 60 * 1000;
                const formattedDate = Highcharts.dateFormat(
                    '%A, %b %e, %Y %H:%M:%S',
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.x - timezoneOffset
                );
                return formattedDate;
            },
        },
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
};
